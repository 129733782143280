import { DateTime } from 'luxon';
import { customAlphabet } from 'nanoid';
import { sep } from 'path';

export const formatDateTime = (jsTimestamp) => {
  if (!jsTimestamp || typeof jsTimestamp !== 'number') {
    return '';
  }
  const dt = DateTime.fromJSDate(new Date(jsTimestamp));
  return `${dt.toLocaleString(DateTime.DATE_SHORT)} ${dt.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}`;
};

export const formatDate = (jsTimestamp) => {
  if (!jsTimestamp || typeof jsTimestamp !== 'number') {
    return '';
  }
  const dt = DateTime.fromJSDate(new Date(jsTimestamp));
  return `${dt.toLocaleString(DateTime.DATE_SHORT)}`;
};

export const base64Encode = (text) => {
  return Buffer.from(text, 'utf8').toString('base64');
};

// export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;   // This was causing the form to freeze.
//export const emailRegex = /^\w+([-.]\w+)*@\w+([-.]\w+)*\.\w{2,4}$/;
// from https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type%3Demail)
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/**
 * Parses raw emails from a string.
 *
 * @param {string} raw - The raw string containing the emails.
 * @returns {string[]} An array of parsed emails.
 */
export const parseRawEmails = (raw) => {
  if (!raw) {
    return [];
  }
  raw = raw.trim();
  let seperator = ',';
  if (raw.indexOf('\n') > -1) {
    seperator = '\n';
  }
  return raw.split(seperator).map((e) => e.trim().toLowerCase());
};

export const escapeHtml = (text) => {
  return text.replace(/[&<>"]/g, (tag) => {
    var chars_to_replace = {
      '&': '&',
      '<': '<',
      '>': '>',
      '"': '"',
    };

    return chars_to_replace[tag] || tag;
  });
};

export const getHost = () => {
  if (process.env.NEXT_PUBLIC_HOST) {
    return process.env.NEXT_PUBLIC_HOST;
  }
  if (window) {
    return window.location.hostname;
  }
  return 'host';
};

export const parseQueryString = (queryString) => {
  if (!queryString || typeof queryString !== 'string') {
    return '';
  }
  if (queryString.startsWith('?')) {
    queryString = queryString.substring(1);
  }
  let params = {};

  // Split into key/value pairs
  const queries = queryString.split('&');

  // Convert the array of strings into an object
  for (let i = 0; i < queries.length; i++) {
    const temp = queries[i].split('=');
    params[temp[0]] = temp[1];
  }

  return params;
};

const guidRegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
export const isGuid = (maybeGuid) => {
  const retVal = guidRegexExp.test(maybeGuid);
  return retVal;
};

export const generateUUID = () => {
  let d = new Date().getTime(); //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const generateUniqueId = (size) => {
  const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', size);
  return nanoid(); //=> "4f90d13a42"
};

export const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order.toLowerCase() === 'desc' ? comparison * -1 : comparison;
  };
};

export const stringHasValue = (val) => {
  if (val === null || val === undefined) {
    return false;
  }
  if (typeof val !== 'string') {
    return false;
  }
  return val.length > 0;
};

export const parseBool = (val) => {
  if (typeof val === 'boolean') {
    return val;
  }
  if (val === null || val === undefined) {
    return false;
  }
  if (typeof val === 'string') {
    return JSON.parse(val.toLowerCase());
  }
  return false;
};

export const safe_atob = (a) => {
  if (typeof window !== 'undefined' && window.atob) {
    return window.atob(a);
  }
  return Buffer.from(a, 'base64').toString('binary');
};

export const safe_btoa = (b) => {
  if (typeof window !== 'undefined' && window.btoa) {
    return window.btoa(b);
  }
  return Buffer.from(b).toString('base64');
};

export const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return safe_btoa(binary);
};

export const removeGuid = (item) => {
  return item.replace(
    /[0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f]-[0-9a-f][0-9a-f][0-9a-f][0-9a-f]-[0-9a-f][0-9a-f][0-9a-f][0-9a-f]-[0-9a-f][0-9a-f][0-9a-f][0-9a-f]-[0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f]/,
    ''
  );
};

export const lastNameFirst2FirstNameLast = (name, seperator = ',') => {
  if (!name) return 'N/A';
  if (name.includes(seperator)) {
    const nameArray = name.split(seperator);
    return `${nameArray[1]} ${nameArray[0]}`;
  }
  return name;
};

export const stripChars = (val, allowNewLine) => {
  val = val.replaceAll(/:/g, ''); // remove colon
  if (!allowNewLine) {
    val = val.replaceAll(/(?:\r\n|\r|\n)/g, ' '); // replace newline with space
  }
  return val;
};

export const isAlphaNumeric = (val) => {
  return /^[a-zA-Z0-9]+$/.test(val);
};

export const states = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AS', label: 'American Somoa' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'BI', label: 'Bureau of Indian Affairs' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];
